.jacky_image {
width: 20%;
    height: auto;

    position: absolute;
    bottom: 0px;
    right: 30px;
z-index: -1;

    @media (max-width:1100px) {

        display: none;
    }

}
.image_cover{
    width: 100%;
    height: 1px;
    background-color: var(--cream);
    position: absolute;
    bottom: 18px;
    transform: rotateZ(var(--oppaside));
}
.jacky_image_container{
        width: 30vw;
            max-width: 300px;
    height:100%;
min-height: 700px;

   position: relative;
        @media (max-width:1100px) {
        
                display: none;
            }

}
.section_1_container{
    width: 100vw;
    height: fit-content;
    min-height: 700px;
    /* padding-bottom: 100px; */
    transform: rotateZ(var(--deg));
    margin-left: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top:50px;
        @media (max-width:600px) {
        
                flex-direction: column;
                
            }
}
.side_images{
    display: flex;
    flex-direction: column;
        width: fit-content;
        padding-left: 20px;
        @media (max-width:600px) {
        
                flex-direction: row-reverse;
                padding-right:30px;
            }
}
.side_images_img{
    width: 200px;
        @media (max-width:600px) {
        
               width: 33vw;
        
            }
            /* transform: translateX(-100vw); */
        }
/* }
.side_images_img:nth-child(1){
    animation: slide forwards 0.5s 0s;
}
.side_images_img:nth-child(2) {
    animation: slide forwards 0.75s 0.25s;
}
.side_images_img:nth-child(3) {
    animation: slide forwards 1s 0.5s;
} */
 @keyframes slide {
     0% {
         transform: translateX(-100vw);

     }


     100% {
                transform: translateX(0px);
     }
 }
.section_1_text{
    width:100%;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 100px;
            @media (max-width:600px) {
            
                   width: 90%;
                    padding-right: 10px;
                }
                direction: rtl;
                display: flex;
                flex-direction: column;
                align-items: center;
                                        color: black;
                                        text-align: center;
                                       gap: 20px;
}

.section_1_text_header {
    font-weight: 600;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    gap: 10px;
    padding-left: 30px;
}
.section_1_text_header span{
    font-size: var(--l-font);
}
.section_1_text p{
   width: 80%;
}
.logos_header{
    position: relative;
    height: 80px;
    width: 90%;

}
.logos_header img{
    width: 45%;
    max-height: 50px;
    margin-top: 5px;
}