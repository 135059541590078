:root {
  --porpul1: rgb(116, 0, 184);
  --porpul2: #6930C3;
  --porpul3: #5E60CE;
  --blue1: #4e8ad4;
  --blue2: #4EA8DE;
  --blue3: #48BFE3;
  --blue3: #48BFE3;
  --blue4: #56CFE1;
  --turkiz1: #64DFDF;
  --turkiz2: #72EFDD;
  --turkiz3: #80FFDB;
}
*{
  font-size: var(--m-font);
}

/* .color_1 {
  width: 100px;
  height: 60px;
  border-radius: 10px;
  background-image: linear-gradient(to right, var(--porpul1), var(--porpul2), var(--porpul3), var(--blue1),
      var(--blue2),
      var(--blue3),
      var(--blue4), var(--turkiz1));
} */

.app_page {
  height: 100%;
  width: 100%;
  overflow-x: hidden;

}

.app_page::-webkit-scrollbar {
  display: none;

}