.section_3_container{
width: 100vw;
    color: black;
    height:100%;
    min-height:300px;
    /* padding-bottom: 100px; */
    transform: rotateZ(var(--oppaside));
    margin-left: 40px;
    margin-top: 180px;
    display: flex;
    direction: rtl;
    align-items: center;
    justify-content: space-around;
    gap: 50px;
    /* margin-bottom: 150px; */
    /* padding-bottom: 50px; */
    flex-wrap: wrap;
    @media (max-width:800px) {
            flex-direction: column-reverse;
        }
}

.katalog_image{
    width: 40vw;
    height: 20vw;
 max-width: 400px;
 max-height: 200px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

}
/* .katalog_image:nth-child(1) {
    animation: slide forwards 0.5s 0s;
} */

/* .katalog_image:nth-child(2) {
    animation: slide forwards 0.75s 0.25s;
}

.katalog_image:nth-child(3) {
    animation: slide forwards 1s 0.5s;
} */
.section_3_katalogs{
    display: flex;
    flex-direction: column;
    align-items: center;
 gap: 5px;
 width: 30vw;
 min-width: 300px;
 height: fit-content;
}
.section_3_best_sells{
    width: 30vw;
    height: 40vw;
    min-height: 350px;
    min-width: 350px;
    background-color: rgba(255, 255, 255, 0.53);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
align-items: center;}
.section_3_best_sells span{
    font-size:var(--l-font);
    font-weight: 600;
}
.best_sells_slide{
   
         height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 10px;
           
        
}
.best_swiper .swiper-button-next{
    margin-left: -80px !important;

}
.best_swiper .swiper-button-prev {
    margin-right: -80px !important;

}