 .section_5_container{
        width: 100vw;
            height: fit-content;
            min-height: 700px;
            /* padding-bottom: 100px; */
            transform: rotateZ(var(--deg));
padding-top: 80px;
margin-top: 20px;
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            /* justify-content: space-around; */
            padding-bottom: 50px;
            direction: rtl;
            color: black;
            gap: 50px;

 }
 .section_5_header{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
 
 }
 .section_5_header span:nth-child(1){
    font-size: calc(var(--l-font) + 6px);
    color: var(--red);
 }
  .section_5_header span:nth-child(2) {
      font-size: var(--l-font);
      color: var(--blue);
  }
.section_5_main{
    display: flex;
flex-direction: column;
gap: 40px;
align-items: center;
justify-content: space-around;
}
.section_5_main_first{
    width: calc(100vw);
    display: flex;
    flex-wrap: wrap;
justify-content: space-around;
    /* flex-direction: column; */
    align-items: center;
    min-width: 350px;
}
.text_section{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
padding: 10px;
width: 50vw;
padding-left: 20px;
padding-right: 20px;
margin-bottom: 10px;
@media (max-width:700px) {
width: 80vw;    }
}
.section_5_main_second {
    width:100vw; 
        display: flex;
        justify-content: space-around;
            flex-wrap: wrap;
            /* flex-direction: column; */
            align-items: center;
                min-width: 350px;
                @media (max-width:700px) {
                       flex-direction: column-reverse;
                    }
}
.section_5_images{
width: auto;
height: 100%;
display: flex;
flex-direction: column-reverse;
align-items: center;

gap: 5px;
@media (max-width:700px) {
        flex-direction: row;
        justify-content: space-around;
    }
}
.section_5_image{
    width: 100%;
    max-width: 30vw;
    max-height: 160px;
    
}
.section_5_more_pro{
    font-weight: 700;
            color: var(--blue);
white-space: nowrap;
background-color: transparent;
border: none;
}
.section_5_more_pro:hover{
    transform: scale(1.1);
    transition: all 0.3s;
}
.oneplace_img{
    padding-top: 50px;
    width: 40vw;
    max-width: 400px;

}