.projects_page{
    width: 100vw;
    height: fit-content;
    min-height: 100vh;
    background-color: var(--cream);
   
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

display: flex;

flex-direction: column;

}

.pro_page_header{
    font-size: var(--l-font);
    color: var(--red);
    width: 100vw;
    text-align: center;


}
.pro_swiper_container{
    width: 100vw;
    direction: rtl;
    display: flex;
    flex-direction: column;
  
}
.pro_header {
padding-right: 50px;
    padding-bottom: 10px;
    height: fit-content;
    padding-left: 20px;
}
.pro_header span{
        color: var(--red);
        font-weight: 600;}
.pro_swiper_div{
    width: 100vw;
    height: 400px;
}
.project_image{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 30vw;
    height: 100%;
    min-width: 350px;
}
/* .pro_page_swiper .swiper-button-next{
text-shadow: 4px 2px 3px   black;
scale: 1.2;
}
.pro_page_swiper .swiper-button-prev{
text-shadow: 4px 2px 3px black;
scale: 1.2;
} */

.pro_dis{
    width: 50vw;
    @media (max-width:800px) {
            width: 100%;
          
        }
        text-align: justify;
}
.projects_page_container{
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding-top: 60px;
}