.section_4_container{
    width: 100vw;
        height: fit-content;
        min-height: 700px;
        /* padding-bottom: 100px; */
        transform: rotateZ(var(--oppaside));
        margin-left: 40px;
        display: flex;
        justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
   padding-top: 10px;
   padding-bottom: 50px;
   direction: rtl;
   margin-top: 50px;
        @media (max-width:800px) {
        
padding-bottom: 100px;        
            }
     margin-bottom: 30px;
}
.section_4_about_me{
    width: 40vw;
    height:fit-content;
    min-width: 350px;
    color: black;
    direction: rtl;
    text-align: center;
    padding-right: 20px;
    
}

.section_4_make_contact{
    width: 50vw;
    height: auto;
    min-width: 370px;
    display: flex;
    

}
.paralle_div{
 
width: fit-content;
max-width: 650px;  
height: 35px;

display: flex;
justify-content: center;

}

.section_4_sentences{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    margin-top: 20px;
}
.section_4_sentences span{
        font-size:calc(var(--m-font) - 2px) ;
}
.section_4_jacky_container{
    width: 30%;
    height: 100%;
position: relative;
        @media (max-width:1000px) {
                
display: none;                
                    }
}
.section_4_jacky_container img{
    width: 100%;
    position: absolute;
    bottom: -10px;


}
.make_contact_container{
    width: 70%;
    min-width: 370px;
    height: calc(90% - 20px);
    display: flex;
    flex-direction: column;
    gap: 30px;
            @media (max-width:800px) {
            
                    width: 100%;
                }
}
.make_contact_div{
    width: 100%;
    min-width: 370px;
    min-height: 400px;
        background-color: var(--light-white)  ;
        /* backdrop-filter: blur(50px); */
             display: flex;
        flex-direction: column;
   align-items: center;
   justify-content: space-around;
   
}
.make_contact_container span{
height: 40px;
    color: black;
    font-size: calc(var(--l-font) - 6px);
    text-align: center;
    margin-top: 6px;
}
.section_4_input_container{
   display: flex;
   flex-direction: column;
   width: 80%;
   height: calc(100% - 120px);
   justify-content: space-around;

}
.section_4_container input{
    height: 50px;
border: none;
text-align: center;
    font-size: 20px;
}
.section_4_container input,textarea:focus{
    outline: none;
}
.section_4_container textarea{
    height: 30%;
    border: none;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 20px;
}
.section_4_container button{
    height: 40px;
    width: 120px;
    background-color: var(--blue);
    color: white;
    border: none;
    margin-bottom: 5px;
}
.address_container{
    width: 100%;
    min-width: 370px;
    height: 150px;
        background-color:var(--light-white);
        display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
}
.thank_you_container{
    width: 100%;
        min-width: 370px;
        min-height: 400px;
        background-color: var(--light-white);
        /* backdrop-filter: blur(50px); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        
}
.thank_you_container h1{
    width: 80%;
    font-size: 30px;
    font-weight: 500;

}