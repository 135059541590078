
:root{
    --deg:2deg;
    --oppaside:-2deg;
    --blue:#4185dc;
    --cream:rgb(232, 224, 211);
    --red:#cd2121;
    --light-white: #ffffffe4;
    --black:#3b5b8a;
        --white: #e8e8e8;
                        --m-font: 26px;
                        --l-font:32px;
        @media (max-width:1000px) {
                --m-font: 18px;
  --l-font:26px;

            }


}
.home_page {
width:100vw;    height:100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
    min-height: 100vh;
    text-align: center;
 /* position: fixed;
 z-index: 1;
 top: 0;
 left: 0; */
 min-height: 200vh;
display: flex;
    flex-direction: column;
 
    scroll-behavior: smooth;
}
.section_3_bg_image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    width: 100vw;

    z-index: 0;
    position: fixed;

    padding-bottom: 50px;
}
.home_page_container{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
overflow-x: hidden;
    scroll-behavior: smooth;
 }
.tiled_header_container{
    background-color:var(--black);
    width: calc(100vw + 30px);
    height: 140px;
    position: fixed;
    transform: rotateZ(var(--deg));
    display: flex;
    top: 0;
    margin-top: -40px;
    left: 0;
    margin-left: -10px;
    align-items: center ;
    justify-content: center;
z-index: 2;
@media (min-width:1000px) {
    padding-bottom: 20px;
    }
    /* box-shadow: inset  0 0 0 4px  var(--red); */
}
.tiled_header_container header{
    margin-top: 30px;
    transform: rotateZ(var(--oppaside));
    height: 60px;
    padding-right: 30px;
    align-items: center;
    @media (min-width:1000px) {
    display: grid;
grid-template-columns:1fr 300px;  
    }  
width: 100vw;
margin-right: 50px;
font-weight: 600;

/* position: fixed;
z-index: 2; */
}
.logo_name{
    display: flex;
align-items: center; 
width: 100%;   
direction: rtl;
@media (min-width:1000px) {
    margin-top: 10px;
    }
}
.tow_lines_header{
display: flex;
    flex-direction: column;
}
.first_name{
font-size: 28px;
width: 100px;
}
.second_name{
font-size: 16px;
}
.tiled_header_container header ul{
display: flex;
list-style: none;
justify-content: space-around;
direction: rtl;
align-items: center;
text-align: start;
@media (max-width:1000px) {
        display:none;
    }
}
.nav_button{
   color: white;
    background-color: transparent;
    border: none;
    font-size: 18px;
    font-weight: 600;
}
.nav_button:hover{
    scale: 1.3;
    transition: all 0.5s;
}
.menu_icon{
    color: white;
            position: absolute;
left: 30px;
top: 20px;
font-size: 30px;
            @media (min-width:1000px) {
            display: none;
        }
}
.home_page_container main{
    margin-top: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
}
.section_1{
margin-top: -150px;
padding-top: 150px;
background-color:var(--cream);
    width: calc(100vw + 100px);
       margin-left: -50px;
    transform: rotateZ(var(--oppaside));
    height: fit-content;
    min-height: 300px;
    
}
.red_div{
    background-color: var(--red);
        height:20px;
        transform: rotateZ(var(--oppaside));
        width: calc(100vw + 100px);
        margin-left: -50px;
        
}
.red_div_2{
    background-color: var(--red);
        height: 20px;
        transform: rotateZ(var(--oppaside));
        width: calc(100vw + 100px);
        margin-left: -50px;
        z-index: 3;
}
.section_2 {
background-color: var(--cream); height: fit-content;
    min-height: 300px;
    /* margin-bottom:150px; */
    width: calc(100vw + 100px);    margin-left: -50px;

            transform: rotateZ(var(--oppaside));

        }
        
        
.section_3 {
    height: fit-content;
    /* min-height: 300px; */
    width: calc(100vw + 100px);
    margin-left: -50px;

    transform: rotateZ(var(--deg));
    margin-top: -100px;
margin-bottom: 50px;}

.section_4 {
/* background-color: var(--blue); */
height: fit-content;
    min-height: 300px;
    width: calc(100vw + 100px);
    margin-left: -50px;
/* margin-top: -30px; */
padding-top: 100px;
    transform: rotateZ(var(--deg));
    @media (max-width:1000px) {
           padding-top: 50px;
        }
}
.section_5 {

background-color: var(--cream);    
height: fit-content;
    width: calc(100vw + 100px);
       margin-left: -50px;

    transform: rotateZ(var(--oppaside));
                    margin-top: -100px;}


.page_cover{
    position: fixed;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.619);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.close_menu{
    width: 100%;
    height: 100%;
}
 .menu_page {
     position: fixed;
     left: 0;
     top: 0;
     width: 50vw;
     max-width: 400px;
     height: 100vh;
     z-index: 4;
  
 }
.contect_icons{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  color: white;
  

}
 .menu_container {
     width: calc(50vw );
     height: 100%;
     background-color: rgb(59, 58, 58);
     animation: grow 0.5s forwards;
     position: relative;
margin-left: -30px;
padding-left: 30px;
 }

 @keyframes grow {
     0% {
         transform: translateX(-200px);

     }

     50% {
         transform: translateX(30px);
         ;
     }

     70% {
         transform: translateX(-20px);
         ;
     }

     90% {
         transform: translateX(10px);
         ;
     }

     100% {
         transform: translateX(0px);

     }
 }
  @keyframes appear {
      0% {
         opacity: 0%;

      }


      100% {
     opacity: 100%;
      }
  }
 .menu_list{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;

 }
 .menu_list button{background-color: transparent;

font-size: 18px;
color: rgb(255, 255, 255);
border: none;
 }
 .close_icon{
    font-size: 30px;
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 4;
    color: white;
    animation: appear 0.8s 0.5s backwards;
 }
.active .nav_button  {
        /* color: var(--red); */
scale: 1.2;
font-weight: 600;
border-bottom: 2px solid white;


}
.elements_container{
  height: auto;
  width: 100vw;
    padding-left: 10px;
    padding-right: 10px;
/* min-height: 700vh; */
  
    scroll-behavior: smooth;
    display: flex;
        flex-direction: column;
        margin-top: 50px;
        overflow-x: hidden;
}
.elements_footer{
    width: 120vw;
    height: 220px;
    margin-left: -50px;
    margin-top: -50px;
    z-index: 2;
    background-color: var(--cream);
}
.header_image{
    margin-top: 30px;
    width: 320px;
   display: flex;
   
    position: relative;
    @media (max-width:1000px) {
           margin-top: 50px;
        }
    @media (max-width:450px) {
           width: 50vw;
           /* height: 20vw; */
           /* margin-top:calc(35px - 4vw); */
           margin-top: 60px;
        }

}
.header_image img{
    position: absolute;
    width:100% ;
    top: -40px;
    left: 0;

}
.project_page_cover {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.767);
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.project_image_div{
height: 100%;
width:80vw;
max-width: 800px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}
.project_swiper .swiper-button-next {
    margin-left: -80px !important;

}

.project_swiper .swiper-button-prev {
    margin-right: -80px !important;

}
.project_swiper{

    height: 80vw;
            width: 100vw;
            max-height: 800px;
            max-width: 1000px;

}
.project_image_div_container{
height: 100%;
width: 1005;
        display: flex;
        align-items: center;
        justify-content: center;
}
.close_project_icon{
    position: fixed;
    top: 30px;
    left: 20px;
    color: white;
    font-size: 40px;

}
.our_info {
    width: 100vw;
margin-left: 40px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    direction: rtl;
  margin-top: 50px;

}

.info_data {
    width: calc(100vw - 75px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
}

.our_logo_container {
    display: flex;
    align-items: center;
    gap: 20PX;
}

.our_logo {
    border: 1px solid black;
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.our_name {
    font-weight: 700;
}