.section_2_container{
    width: 100vw;
    color: black;
        height: fit-content;
        min-height: 700px;
        /* padding-bottom: 100px; */
        transform: rotateZ(var(--deg));
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    margin-top: 50px;
    padding-top: 50px;
    /* padding-bottom: 100px; */
    /* margin-bottom: 50px; */
        gap: 60px;
        position: relative;
}
.section_2_first_part_container{
    display: flex;
    direction: rtl;
    gap: 50px;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    @media (max-width:650px) {
    flex-direction: column-reverse;
        }
}
.section_2_head_line{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.section_2_header_container{
    display: flex;
    flex-direction: column;
    direction: rtl;
    align-items: center;
width: 40vw;
min-width: 300px;

}
.sectio_2_header{
    color: var(--red);
    font-size:calc(var(--l-font) + 6px);
    font-weight: 600;

}
.section_2_header_2{
    color: var(--blue);
        font-size:var(--l-font);
}
.projects_container{
width: 100%;
    height: fit-content;
        min-height: 400px;
        display: flex;
        justify-content: space-around;
        gap: 30px;
        flex-wrap: wrap;
}
.section_2_bg_video{
    width: 30vw;
    max-width: 500px;
min-width: 300px;
position: relative;
}
.sound_icon{
    position: absolute;
    background-color: white;
    margin: 5px;
    border-radius: 40px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bg_video{
    width: 100%;
}
.projects_container::-webkit-scrollbar {
    display: none;

}
.projects_card{
    text-align: center;
  
}


.projects_card span{
font-size: 18px;
   /* font-size:var(--l-font); */
   white-space: nowrap;
   font-weight: 600;
   color: var(--blue);

}
.image_scroller_container{
    width: 30vw;
    height: 30vw;
    max-height: 600px;
    min-height: 300px;
    max-width: 600px;
    min-width: 300px;
   margin-top: 30px;
   margin-bottom: 60px;
   
   position: relative;
   display: flex;
   flex-direction: column;


}
.image_slide{
    height: 30vw;
        max-height: 600px;
        min-height: 300px;
        max-width: 600px;
        min-width: 300px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.project_header{
margin-bottom: 20px;
/* font-size: 18px !important; */
}


.more_projects{
    direction: rtl;
    position: absolute;
bottom: 10px;
left: 20px;
font-size:var(--m-font);
font-weight:700 ;
background-color: transparent;
border: none;
z-index: 3;
}
.more_projects:hover{
    scale: 1.1;
    transition: all 0.3s;
}